<template>
	<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32">
		<h2 class="leading-6 text-primary-900 text-sm font-semibold tracking-wide uppercase">learnerships</h2>
		<div class="pageheader">{{ learnership.name }}</div>
		<div class="pagepara">{{ learnership.description }}</div>

		<div class="pb-8">
			<CourseItem v-for="(course, i) in learnership.courses" :key="i" :item="course" />
		</div>
	</div>
</template>

<script>
// import {MenuIcon,XIcon} from '@heroicons/vue/solid';
// import MenuItem from "./MenuItem.vue";
// import HeaderBanner from "./HeaderBanner.vue";
import CourseItem from '@/components/CourseItem.vue';
import site_data from '@/assets/data/site-data';
export default {
	data: () => {
		return {
			learnership: null,
			learnerships: site_data.Learnerships,
		};
	},
	components: {
		CourseItem,
		// MenuItem,
		// HeaderBanner,
		// MenuIcon,
		// XIcon,
	},
	mounted() {
		//console.log("mounted");
		this.$gtag.event('learnerships', { method: 'Google' });
	},
	// a beforeMount call to add a listener to the window
	beforeMount() {
		//console.log("beforeMount");
		var id = this.$route.params.id - 1;
		//console.log(id);
		this.learnership = this.learnerships[id];
	},
	updated() {
		//console.log("updated");
		var id = this.$route.params.id - 1;
		//console.log(this.$route.params);
		this.learnership = this.learnerships[id];
	},
};
</script>

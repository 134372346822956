<template>
	<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-36">
		<div class="bg-white overflow-hidden">
			<div class="relative max-w-7xl">
				<h2 class="leading-6 text-primary-900 text-xs sm:text-sm font-semibold tracking-wide uppercase">unpacking the value of deaf learnerships</h2>
				<div class="pageheader">Learnership Benefits</div>
				<div class="py-3">
				<div class="pagepara">
					Ask us for up to date costs on our variety of learnerships on offer.
				</div>
				</div>
				<div class="sectionheader mt-3">Stipend</div>
				<div class="pagepara">
					The learner receives the full stipend minus standard UIF deductions. We carefully source candidates near our client’s premises or within close proximity to our training
					campuses so that transport costs are kept low.
				</div>
				<div class="sectionheader mt-3">Direct Benefits to Learners include the following:</div>
				<div class="grid md:grid-cols-2">
					<div v-for="(b, i) in benefits" :key="i" class="flex pb-2">
						<div><CheckCircleIcon class="w-6 h-6 text-primary-900 mr-2 mt-4" /></div>
						<div class="pagepara mr-2">{{ b }}</div>
					</div>
				</div>
				<div class="sectionheader mt-3">Operational Costs</div>
				<div class="pagepara">
					Our operational costs are fully geared to support and accommodate the needs of unemployed Deaf youth. As a result, our unique selling point is employable, work ready,
					Deaf South Africans. Costs include training rooms, electrics, admin, marketing, interpreters, reasonable accommodation and departmental costs.
				</div>
				<div class="py-6">
					<blockquote class="font-serif font-semibold text-xl italic">“Diversity is the mix. Inclusion is making the mix work”</blockquote>
					<div class="text-xs">Andres Tapia</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { CheckCircleIcon } from '@heroicons/vue/solid';
import site_data from '@/assets/data/site-data';
export default {
	data: () => {
		return {
			benefits: site_data.LearnerBenefits,
		};
	},
	components: {
		CheckCircleIcon,
	},
	mounted() {
		this.$gtag.event('learnership value', { method: 'Google' });
	},
	// a beforeMount call to add a listener to the window
	beforeMount() {},

	methods: {},
};
</script>

<template>
	<div class="text-gray-600 pt-28 sm:pt-32 pb-4 max-w-7xl mx-auto">
		<carousel class="" :autoplay="8000" :wrap-around="true" :items-to-show="1" :transition="1000">
			<slide v-for="slide in slides" :key="slide">
				<component :is="slide.name" />
			</slide>
			<template #addons>
				<pagination class="bg-primary-600" />
			</template>
		</carousel>
		<div class="text-gray-600 font-semibold py-3 max-w-7xl bg-secondary-100 flex justify-center items-center">
			<div>Looking for interpreter services?</div>			
			<div class="buttonprimary ml-3">
				<button @click="bookNow()">Book now through DEAFinition</button>
				</div>
		</div>
	</div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import BEEBenefits from '../components/BEEBenefits.vue';
import BursaryBenefits from '../components/BursaryBenefits.vue';
import HeartBenefits from '../components/HeartBenefits.vue';
import DeafinitionSlide from '../components/DeafinitionSlide.vue';

export default {
	name: 'Home',
	components: { Carousel, Slide, Pagination, BEEBenefits, BursaryBenefits, HeartBenefits, DeafinitionSlide },
	data: () => {
		return {
			slides: [{ name: 'BursaryBenefits' }, { name: 'BEEBenefits' }, { name: 'HeartBenefits' }, { name: 'DeafinitionSlide' }],
			//slides: [{"name": "DeafinitionSlide"}]  //Testing
		};
	},
	methods: {
    bookNow() {
      this.$gtag.event('book-now-deafinition', { method: 'Google' })
		window.location.href = "https://www.deafinition.co.za/interpreter-serve"
    },
  }
};
</script>

<style>
.carousel__slide {
	height: 430px;
}

.carousel__prev,
.carousel__next {
	box-sizing: content-box;
}

:root {
  /* Color */
  --vc-clr-primary: #00a29a;
  --vc-clr-secondary: #b3eee8;
  --vc-clr-white: #ffffff;

  /* Icon */
  --vc-icn-width: 1.2em;

  /* Navigation */
  --vc-nav-width: 30px;
  --vc-nav-color: #ffffff;
  --vc-nav-background-color: var(--vc-clr-primary);

  /* Pagination */
  --vc-pgn-width: 10px;
  --vc-pgn-height: 5px;
  --vc-pgn-margin: 5px;
  --vc-pgn-border-radius: 0;
  --vc-pgn-background-color: var(--vc-clr-secondary);
  --vc-pgn-active-color: var(--vc-clr-primary);
}
</style>

import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import AboutUs from '../views/AboutUs.vue';
import Contact from '../views/Contact.vue';
import Learnerships from '../views/Learnerships.vue';
import Skills from '../views/Skills.vue';
import LearnerInfo from '../views/LearnerInfo.vue';
import LearnershipValue from '../views/LearnershipValue.vue';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/about',
		name: 'About Us',
		component: AboutUs,
	},
	{
		path: '/contact',
		name: 'Contact Us',
		component: Contact,
	},
	{
		path: '/learnerships/:id',
		name: 'Learnerships',
		component: Learnerships,
	},
	{
		path: '/skills/:id',
		name: 'Skills',
		component: Skills,
	},
	{
		path: '/learners',
		name: 'LearnerInfo',
		component: LearnerInfo,
	},
	{
		path: '/learnership-value',
		name: 'LearnershipValue',
		component: LearnershipValue,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		return { top: 0 };
	},
});

export default router;

<template>
	<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32">
		<h2 class="leading-6 text-primary-900 text-sm font-semibold tracking-wide uppercase">information for deaf learners</h2>
		<div class="pageheader">Learner Information Videos</div>
		<div class="h-96 mt-3">
			<vue-plyr ref="plyr">
				<video controls crossorigin playsinline>
					<source size="576" src="https://edeafstorage.blob.core.windows.net/media/intro.mp4" type="video/mp4" />
				</video>
			</vue-plyr>
		</div>
		<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 py-6 text-sm font-semibold hover:cursor-pointer">
			<div class="content-card" v-for="(video, i) in videos" :key="i" @click="loadVideo(video.src)">{{ video.description }}</div>
		</div>
	</div>
</template>

<script>
import site_data from '@/assets/data/site-data';

export default {
	data: () => {
		return {
			videos: site_data.LearnerVideos,
			source: null,
		};
	},
	components: {
		//CourseItem,
		// MenuItem,
		// HeaderBanner,
		// MenuIcon,
		// XIcon,
	},
	mounted() {},
	// a beforeMount call to add a listener to the window
	beforeMount() {},

	methods: {
		loadVideo(src) {
			const player = this.$refs.plyr.player;
			player.source = {
				type: 'video',
				sources: [
					{
						src: src,
						type: 'video/mp4',
						size: 720,
					},
				],
			};
			console.log(src);
			player.play();
		},
	},
};
</script>

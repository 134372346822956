<template>
	<div class="">
		<div class="slide-img">
			<img class="object-contain" alt="Slide background" src="@/assets/images/bg3.jpg" />
		</div>
		<div class="slide-bg">
			<div class="slide-title">Heart Benefits</div>
			<div class="slide-content">
				<div>According to the Western Cape Government statistics, there are more than 4 million Deaf and hard of hearing people in South Africa, and over 70% are unemployed.</div>
				<div class="">Your company can make a meaningful difference in the life of a Deaf person.</div>
			</div>
			<div class="flex gap-3">
				<div><a class="buttonprimary mt-2 sm:mt-6 hidden sm:block" href="/learnership-value">Find out more</a></div>
				<div><button @click="enquireNow()" class="buttonsecondary mt-6 hidden sm:block">Enquire Now</button></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
    enquireNow() {
		this.$gtag.event('enquire-now-heart', { method: 'Google' })
		this.$router.push("/contact");
    },
}
}
</script>
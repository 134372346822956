<template>
	<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-36">
		<div class="bg-white overflow-hidden">
			<div class="relative max-w-7xl">
				<div class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
				<div class="mx-auto text-base lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
					<div>
						<h2 class="text-sm text-primary-900 font-semibold tracking-wide uppercase">Nazereen Captieux-Bhana</h2>
						<h3 class="pageheader">Meet our CEO</h3>
					</div>
				</div>
				<div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
					<div class="relative lg:row-start-1 lg:col-start-2">
						<svg class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
							<defs>
								<pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
									<rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
								</pattern>
							</defs>
							<rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
						</svg>
						<div class="relative text-base mx-auto max-w-prose lg:max-w-none w-96">
							<figure>
								<div class="aspect-w-12 aspect-h-7 lg:aspect-none">
									<img class="rounded-lg shadow-lg object-cover object-center" src="@/assets/images/nazereen.jpg" alt="Nazereen - eDeaf CEO" />
								</div>
								<figcaption class="mt-3 flex text-sm text-gray-500">
									<span class="ml-2">eDeaf CEO - Nazereen Captieux-Bhana </span>
								</figcaption>
							</figure>
						</div>
					</div>
					<div class="mt-8 lg:mt-0">
						<div class="text-base mx-auto lg:max-w-none">
							<p class="text-lg text-gray-500">Nazereen Captieux-Bhana is Deaf, and the CEO of Employ & Empower Deaf (eDeaf).</p>
						</div>
						<div class="mt-5 text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
							<div class="pagepara">
								Being born Deaf, Nazereen is all too familiar with the struggles and barriers faced by Deaf people every day. She is a seasoned television presenter and was
								previously the anchor host for a long-running SABC programme called ‘Signature’.
							</div>
							<div class="pagepara">
								Nazereen is a qualified assessor and moderator for Deaf learnerships, as well as a National mentor to those coming through the ranks. She has spent most of her life
								actively involved in lobbying for and empowering the Deaf community of South Africa.
							</div>
							<div class="pagepara">Nazereen is available for motivational and Deaf sensitization talks at your offices. Contact us for more information.</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<h2 class="leading-6 text-primary-900 font-semibold tracking-wide uppercase text-sm mt-6">more about edeaf</h2>
		<div class="pageheader">About Us</div>
		<div class="pagepara">
			Established in 2007, eDeaf is a Deaf owned, B-BBEE Level 2 company, we are the leading provider of (Deaf) Skills Development training in South Africa. Through our partnered
			solutions approach, we provide a holistic, end-to-end service, to both the employer and the Deaf learner/employee in terms of training, recruitment, ongoing support services,
			as well as interpreter services.
		</div>
		<div class="pagepara">
			We operate nationally with well equipped training facilities in Johannesburg, Centurion, Cape Town, Durban, and Mthatha. We are able to offer the ideal learning environment
			for Deaf individuals who learn in their home language South African Sign Language (SASL) through our experienced and qualified Deaf facilitators. eDeaf is proud to have
			nearly 70 employees, 80% of which are Deaf.
		</div>
		<div class="pagepara">
			eDeaf strives to improve the social and economic lives of the Deaf community, through a variety of empowerment and skills development programmes. By adding value, not only to
			the individuals we train, we create employable Deaf individuals who are able to contribute to the economy. We reduce reliance on social grants and thereby boost the overall
			economy.
		</div>
		<div class="w-1/2 mx-auto">
			<img class="my-8 h-1/6" src="@/assets/images/values-wheel.png" alt="values wheel" />
		</div>
	</div>
</template>

<script>
//import { CameraIcon } from '@heroicons/vue/solid'

export default {
	components: {
		//  CameraIcon,
	},
	mounted() {
		this.$gtag.event('about-us', { method: 'Google' });
	},
};
</script>

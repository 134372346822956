<template>
	<nav class="border-b fixed animated w-full bg-white" :class="{ scrolled: !view.atTopOfPage }">
		<HeaderBanner />
		<!-- Mobile menu, show/hide based on menu state. -->
		<transition
			enter-active-class="transition duration-200 ease-out"
			enter-from-class="translate-x-1 opacity-0"
			enter-to-class="translate-x-0 opacity-100"
			leave-active-class="transition duration-200 ease-in"
			leave-from-class="translate-x-0 opacity-100"
			leave-to-class="translate-x-1 opacity-0"
		>
			<div v-if="showMobileMenu" class="absolute md:hidden pt-20 w-full" id="mobile-menu">
				<div class="grid grid-cols-1 bg-white px-3 py-3 shadow rounded">
					<div class="pt-2">
						<h3 class="mobile-menu-header">
							<a href="/about">About us</a>
						</h3>
					</div>
					<div class="pt-2">
						<h3 class="mobile-menu-header">Learnerships</h3>
						<ul class="">
							<li v-for="(item, i) in learnerships" :key="i" class="pt-1">
								<a :href="item.route" class="text-sm tracking-tighter text-gray-500 hover:text-gray-900">
									{{ item.name }}
								</a>
							</li>
						</ul>
					</div>
					<div class="pt-2">
						<h3 class="mobile-menu-header">Skills Programmes</h3>
						<ul>
							<li v-for="(item, i) in skillsProgrammes" :key="i" class="pt-1">
								<a :href="item.route" class="text-sm tracking-tighter text-gray-500 hover:text-gray-900">
									{{ item.name }}
								</a>
							</li>
						</ul>
					</div>
					<div class="pt-2">
						<h3 class="mobile-menu-header">
							<a href="/contact">Contact</a>
						</h3>
					</div>
				</div>
			</div>
		</transition>

		<div class="w-full mx-auto px-2 sm:px-6 lg:px-8">
			<div class="relative flex h-20">
				<div class="absolute inset-y-0 left-0 items-center md:hidden mt-5 max-w-xl">
					<!-- Mobile menu button - keep hidden for now at small screens -  all links are navigable on the home page -->
					<button
						type="button"
						class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
						aria-controls="mobile-menu"
						aria-expanded="false"
					>
						<span class="sr-only">Open main menu</span>
						<span @click="showMobileMenu = true" v-if="!showMobileMenu" class="h-6 w-6"><MenuIcon /></span>
						<span @click="showMobileMenu = false" v-if="showMobileMenu" class="h-6 w-6"><XIcon /></span>
					</button>
				</div>

				<div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-around">
					<div class="flex-shrink-0 flex items-center">
						<a href="/"><img class="pr-2 block h-20" alt="Deafinition logo" src="@/assets/images/logo_small.png" /></a>
					</div>

					<SocialMedia class="hidden lg:flex" />

					<div class="relative hidden lg:flex space-x-4 md:space-x-6 xl:space-x-8 mt-2 items-center xl:mr-24">
						<MenuItem title="About Us" route="/about" />
						<MenuItem title="Learnerships" :menuItems="learnerships" />
						<MenuItem title="Skills Programmes" :menuItems="skillsProgrammes" />
						<MenuItem title="Contact" route="/contact" />
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
import { MenuIcon, XIcon } from '@heroicons/vue/solid';
import MenuItem from './MenuItem.vue';
import HeaderBanner from './HeaderBanner.vue';
import site_data from '@/assets/data/site-data';
import SocialMedia from '@/components/SocialMedia.vue';
export default {
	data: () => {
		return {
			showMobileMenu: false,
			learnerships: site_data.Learnerships,
			skillsProgrammes: site_data.SkillsProgrammes,
			view: {
				atTopOfPage: true,
			},
		};
	},
	components: {
		MenuItem,
		HeaderBanner,
		MenuIcon,
		XIcon,
		SocialMedia,
	},
	mounted() {
		this.services = site_data.Services;
	},
	// a beforeMount call to add a listener to the window
	beforeMount() {
		window.addEventListener('scroll', this.handleScroll);
	},

	methods: {
		// the function to call when the user scrolls, added as a method
		handleScroll() {
			// when the user scrolls, check the pageYOffset
			if (window.pageYOffset > 0) {
				// user is scrolled
				if (this.view.atTopOfPage) this.view.atTopOfPage = false;
			} else {
				// user is at top of page
				if (!this.view.atTopOfPage) this.view.atTopOfPage = true;
			}
		}

	},
};
</script>

<style scoped>
nav {
	z-index: 100;
}

nav.scrolled {
	@apply shadow;
	border-bottom: 0px;
}
</style>

<template>
	<div
		v-if="!menuItems"
		@click="goToPage(route)"
		class="text-gray-500 bg-white inline-flex items-center text-sm font-medium hover:text-gray-900 focus:outline-none mt-1 hover:cursor-pointer"
	>
		{{ title }}
	</div>
	<Popover v-if="menuItems" class="relative" v-slot="{ open }">
		<PopoverButton
			:class="[open ? 'text-gray-900' : 'text-gray-500', 'group bg-white rounded-md inline-flex items-center text-sm font-medium hover:text-gray-900 focus:outline-none mt-1']"
		>
			<span>{{ title }}</span>
			<ChevronDownIcon
				v-if="menuItems"
				:class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500 transition ease-in-out duration-150']"
				aria-hidden="true"
			/>
		</PopoverButton>

		<transition
			enter-active-class="transition ease-out duration-200"
			enter-from-class="opacity-0 translate-y-1"
			enter-to-class="opacity-100 translate-y-0"
			leave-active-class="transition ease-in duration-150"
			leave-from-class="opacity-100 translate-y-0"
			leave-to-class="opacity-0 translate-y-1"
		>
			<PopoverPanel v-if="menuItems" class="absolute z-10 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0 lg:max-w-md">
				<div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
					<div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 grid-cols-1">
						<a v-for="item in menuItems" :key="item.name" :href="item.route" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
							<div class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-primary-900 text-white sm:h-12 sm:w-12">
								<component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
							</div>
							<div class="ml-4">
								<p class="text-sm font-medium text-gray-900">
									{{ item.name }}
								</p>
								<p class="mt-1 text-sm text-gray-500">
									{{ item.shortDesc }}
								</p>
							</div>
						</a>
					</div>
					<div class="p-5 bg-gray-50 sm:p-8">
						<a href="/learnership-value" class="-m-3 p-3 flow-root rounded-md hover:bg-gray-100 transition ease-in-out duration-150">
							<span class="flex items-center">
								<span class="text-base font-medium text-gray-900"> Learnership Benefits </span>
								<span class="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-secondary-800 text-white"> More Details </span>
							</span>
							<span class="mt-1 block text-sm text-gray-500"> Unpacking the true value of Deaf learnerships </span>
						</a>
					</div>
				</div>
			</PopoverPanel>
		</transition>
	</Popover>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { ChartBarIcon, CursorClickIcon, DocumentReportIcon, RefreshIcon, ShieldCheckIcon, ViewGridIcon } from '@heroicons/vue/outline';

export default {
	components: {
		Popover,
		PopoverButton,
		PopoverPanel,
		ChartBarIcon,
		CursorClickIcon,
		DocumentReportIcon,
		RefreshIcon,
		ShieldCheckIcon,
		ViewGridIcon,
		ChevronDownIcon,
	},
	data() {
		return {};
	},
	mounted() {
		// console.log(this.menuItems)
	},
	methods: {
		goToPage(route) {
			this.$router.push(route);
		},
	},
	props: {
		menuItems: Array,
		title: String,
		route: String,
	},
};
</script>

<style></style>

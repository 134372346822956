<template>
	<div class="">
		<div class="slide-img">
			<img class="sm:object-contain" alt="Slide background" src="@/assets/images/bg1.jpg" />
		</div>
		<div class="slide-bg">
			<div class="slide-title">BBBEE Benefits</div>
			<div class="slide-content">
				<ul class="list-disc ml-5">
					<li>Improve your scorecard</li>
					<li>Invest in meaningful skills development</li>
					<li>BBBEE Level 2</li>
					<li>Black Female Deaf owned</li>
					<li>SETA Accredited</li>
				</ul>
			</div>
			<div class="flex gap-3">
				<div><a class="buttonprimary mt-6 hidden sm:block" href="/learnership-value">Find out more</a></div>
				<div><!-- <a class="buttonsecondary mt-6 hidden sm:block" href="/contact">Enquire Now</a></div> -->
				<button @click="enquireNow()" class="buttonsecondary mt-6 hidden sm:block">Enquire Now</button>
				</div>
		</div>
			</div>
		</div>
</template>

<script>
export default {
	methods: {
    enquireNow() {
		this.$gtag.event('enquire-now-bee', { method: 'Google' })
		this.$router.push("/contact");
    },
}
}
</script>



<style scoped>
</style>

<template>
	<div class="">
		<div class="slide-img">
			<img class="sm:object-contain" alt="Deafinition logo" src="@/assets/images/bg2.jpg" />
		</div>

		<div class="slide-bg">
			<div class="slide-title">Bursary Benefits</div>
			<div class="slide-content">
				The DTI, require that 2.5% of annual payroll be spent on Higher Education by means of bursaries for black students. At eDeaf, corporates can sponsor a Foundational Learning
				Competence (FLC) bursary for a Deaf adults.
			</div>
			<div class="flex gap-3">
				<div><a class="buttonprimary mt-3 sm:mt-6 hidden sm:block" href="/learnership-value">Find out more</a></div>
				<div><button @click="enquireNow()" class="buttonsecondary mt-6 hidden sm:block">Enquire Now</button></div>
			</div>
		</div>

		<div class="bg-white p-4 rounded-lg absolute top-10 right-3 bg-opacity-90 hidden lg:block">
			<div class="slide-content">
				<video autoplay loop width="320" height="240" muted src="https://edeafstorage.blob.core.windows.net/media/are-you-deaf.mp4"></video>
			</div>
			<div class="text-center">
				<div><a class="buttonsecondary mt-2" href="/learners">Are you Deaf? Click here</a></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
    enquireNow() {
		this.$gtag.event('enquire-now-bursary', { method: 'Google' })
		this.$router.push("/contact");
    },
}
}
</script>
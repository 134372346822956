<template>
	<Navbar />
	<main>
		<router-view />
		<Footer />
	</main>
</template>

<script>
import Footer from './components/Footer.vue';
import Navbar from './components/Navbar.vue';
export default {
	components: { Navbar, Footer },
	data() {
		return {};
	},
};
</script>

<style scoped></style>

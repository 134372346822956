<template>
	<Hero />
	<TrainingOptions />
	<WhyPartner />
	<SkillsTown />
	<Offices />
</template>

<script>
import WhyPartner from '../components/WhyPartner.vue';
import TrainingOptions from '../components/TrainingOptions.vue';
import Hero from '../components/Hero.vue';
import SkillsTown from '../components/SkillsTown.vue';
import Offices from '../components/Offices.vue';

export default {
	components: {
		Hero,
		TrainingOptions,
		SkillsTown,
		Offices,
		WhyPartner,
	},
	name: 'Home',
};
</script>

<template>
	<div class="max-w-7xl mx-auto pb-8">
		<div class="text-2xl font-extrabold">Office Locations</div>
		<div class="grid lg:grid-cols-5 gap-6 py-5">
			<div class="border rounded h-52 p-4 shadow-md bg-primary-900 text-white hover:shadow-lg hover:bg-accent-900" v-for="o in offices" :key="o.name">
				<div class="text-lg font-medium">{{ o.name }}</div>
				<div class="text-sm" v-html="formatString(o.address)"></div>
				<div class="text-sm" v-html="formatString(o.phone)"></div>
			</div>
		</div>
	</div>
</template>

<script>
// import {MenuIcon,XIcon} from '@heroicons/vue/solid';
import site_data from '@/assets/data/site-data';
export default {
	data: () => {
		return {
			offices: site_data.Offices,
		};
	},
	components: {},
	mounted() {},
	// a beforeMount call to add a listener to the window
	beforeMount() {},

	methods: {
		formatString(a) {
			return a.split('|').join('<br>');
		},
	},
};
</script>

<template>
	<div class="mt-4 pb-2 sm:mt-6">
		<div class="relative">
			<div class="relative max-w-7xl mx-auto px-1 sm:px-6 lg:px-8">
				<div class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
					<div class="flex-1 bg-white border border-gray-100 px-6 py-8 lg:p-10">
						<h3 class="sectionheader">
							{{ item.CourseTitle }}
						</h3>
						<div class="flex items-center pt-1">
							<div class="flex items-center text-sm text-gray-500 pr-3">
								<ClockIcon class="text-gray-400 mr-1 h-4" />
								{{ item.Duration }}
							</div>
							<div v-if="item.NQFCert" class="hidden sm:flex items-center text-sm text-gray-500">
								<BadgeCheckIcon class="text-gray-400 mr-1 h-4" />
								{{ item.NQFCert }} - SAQA ID: {{ item.SaqaID }}
							</div>
						</div>
						<p class="mt-2 text-base text-gray-500">
							{{ item.Overview }}
						</p>
						<div v-if="item.Modules.length > 0" class="mt-8">
							<div class="flex items-center">
								<h4 class="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-primary-900">Modules</h4>
								<div class="flex-1 border-t-2 border-gray-200"></div>
							</div>
							<ul class="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
								<li v-for="(mod, i) in item.Modules" :key="i" class="flex lg:col-span-1 items-center">
									<div class="flex-shrink-0">
										<CheckCircleIcon class="text-primary-900 w-4" />
									</div>
									<p class="ml-3 text-sm text-gray-700">
										{{ mod }}
									</p>
								</li>
							</ul>
						</div>
						<div v-if="item.Outcomes.length > 0" class="mt-8">
							<div class="flex items-center">
								<h4 class="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-primary-900">Outcomes</h4>
								<div class="flex-1 border-t-2 border-gray-200"></div>
							</div>
							<ul class="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
								<li v-for="(out, i) in item.Outcomes" :key="i" class="flex items-center lg:col-span-1">
									<div class="flex-shrink-0">
										<CheckCircleIcon class="text-primary-900 w-4" />
									</div>
									<p class="ml-3 text-sm text-gray-700">
										{{ out }}
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { CheckCircleIcon, BadgeCheckIcon, ClockIcon } from '@heroicons/vue/solid';

export default {
	components: {
		CheckCircleIcon,
		BadgeCheckIcon,
		ClockIcon,
	},
	props: {
		item: { type: Object },
		showLink: { type: Boolean, default: true },
		showBonusLink: { type: Boolean, default: true },
	},
};
</script>

<template>
	<div class="">
		<div class="">
			<img class="object-fill lg:w-[1800px]" src="@\assets\images\home_hero.jpg" alt="" />
		</div>
		<div class="slide-bg">
			<div class="slide-title flex items-center justify-center sm:justify-start">
				<a target="_blank" href="https://www.deafinition.co.za"><img class="h-12 sm:h-16 mr-2" src="@\assets\images\deafinition_logo_pic.png" alt="" /> </a>
				<a target="_blank" href="https://www.deafinition.co.za"><img src="@\assets\images\deafinition_text_logo.png" alt="" /></a>
			</div>
			<div class="slide-content space-y-2 mt-2">
				<div>DEAFinition is a non-profit company providing a range of services and funding opportunities to promote equal access for the Deaf community.</div>
				<div>Learn South African Sign Language online from only R500 or download the free Sign Language App.</div>
			</div>
			<div class="flex gap-3">
				<div><a class="buttonprimary mt-6 hidden sm:block" target="_blank" href="https://www.deafinition.co.za">Visit site</a></div>
				<div><a class="buttonsecondary mt-6 hidden sm:block" target="_blank" href="https://www.deafinition.co.za/sign-language-courses">SASL Course</a></div>
			</div>
		</div>
	</div>
</template>

<style scoped></style>

<template>
	<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32">
		<h2 class="leading-6 text-sm text-primary-900 font-semibold tracking-wide uppercase">skills programmes</h2>
		<div class="pageheader">{{ skill.name }}</div>
		<div class="pagepara">{{ skill.description }}</div>
		<div class="pb-8">
			<CourseItem v-for="(course, i) in skill.courses" :key="i" :item="course" />
		</div>
	</div>
</template>

<script>
import site_data from '@/assets/data/site-data';
import CourseItem from '@/components/CourseItem.vue';
export default {
	data: () => {
		return {
			skill: null,
			skills: site_data.SkillsProgrammes,
			SlCourses: site_data.SlCourses,
		};
	},
	components: {
		CourseItem,
	},
	mounted() {
		//console.log("mounted");
		this.$gtag.event('skills', { method: 'Google' });
	},
	// a beforeMount call to add a listener to the window
	beforeMount() {
		var id = this.$route.params.id - 1;
		//console.log(id);
		this.skill = this.skills[id];
	},
	updated() {
		var id = this.$route.params.id - 1;
		//console.log(id);
		this.skill = this.skills[id];
	},
};
</script>

<template>
	<!-- This example requires Tailwind CSS v2.0+ -->
	<footer class="bg-gray-50" aria-labelledby="footername">
		<h2 id="footername" class="sr-only">Footer</h2>
		<div class="mx-auto py-8 px-4 sm:px-6 lg:py-8 lg:px-36">
			<div class="xl:grid xl:grid-cols-4 xl:gap-8">
				<div class="space-y-6 grid col-span-2 xl:col-span-1">
					<div><a href="/"><img class="h-16" src="@/assets/images/logo_large.png" alt="eDeaf logo" /></a></div>
					<SocialMedia />
				</div>
				<div class="mt-12 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 xl:mt-0 xl:col-span-3">
					<div class="mt-6 md:mt-4">
						<h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">Learnerships</h3>
						<ul class="mt-4 space-y-4">
							<li v-for="(item, i) in learnerships" :key="i">
								<div @click="linkClick(item.name, item.route)" class="text-sm tracking-tighter text-gray-500 hover:text-gray-900 cursor-pointer">
									{{ item.name }}
								</div>
							</li>
						</ul>
					</div>
					<div class="mt-6 md:mt-4">
						<h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">Skills Programmes</h3>
						<ul class="mt-4 space-y-4">
							<li v-for="(item, i) in skillsProgrammes" :key="i">
								<div @click="linkClick(item.name, item.route)" class="text-sm tracking-tighter text-gray-500 hover:text-gray-900 cursor-pointer">
									{{ item.name }}
								</div>
							</li>
						</ul>
					</div>
					<div class="mt-6 md:mt-4">
						<h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">							
							<div @click="linkClick('contact-footer', '/contact')" class="cursor-pointer">
									Contact
							</div>
						</h3>
					</div>
					<div class="mt-6 md:mt-4">
						<h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">Legal</h3>
						<div class="mt-4 cursor-pointer">
							<a
								@click="showPDF('https://edeafstorage.blob.core.windows.net/media/edeaf-popia.pdf')"
								target="blank"
								class="text-sm tracking-tighter text-gray-500 hover:text-gray-900"
							>
								POPIA Disclaimer
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="mt-12 border-t border-gray-200 pt-4">
				<p class="text-xs text-gray-400 xl:text-center">&copy; 2022 <a class="text-primary-700" href="https://www.cogni.co.za">Cognizance</a> All rights reserved.</p>
			</div>
		</div>
	</footer>
</template>

<script>
import site_data from '@/assets/data/site-data';
import SocialMedia from '@/components/SocialMedia.vue';
export default {
	components: {
		SocialMedia
	},
	data: () => {
		return {
			learnerships: site_data.Learnerships,
			skillsProgrammes: site_data.SkillsProgrammes,
			
		};
	},
	methods: {
		showPDF(path) {
			window.open(path);
		},
		linkClick(name, route) {
		this.$gtag.event(name, { method: 'Google' });
		//console.log('logged: ' + name);
		this.$router.push(route);
    },
	},
};
</script>

<style></style>

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
	<div class="bg-white">
		<div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
			<div class="max-w-7xl">
				<h2 class="sectionheader text-left">Why Partner with eDeaf?</h2>
				<div class="flex items-center">
					<div class="mt-4 text-lg text-gray-500 text-justify mr-6">
						eDeaf is proud to provide partnered solutions to some of the most well known, established and high-profile businesses and brands in South Africa. We believe this
						displays our proven competency and reputation amongst not only the Deaf community but businesses and brands alike.
					</div>
					<div class="w-1/3 max-h-32 hidden sm:block"><img class="object-cover" src="@/assets/images/partnered-solutions.png" alt="values wheel" /></div>
				</div>
			</div>
			<dl class="mt-16 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
				<div v-for="feature in features" :key="feature.name" class="relative">
					<dt>
						<CheckIcon class="absolute h-6 w-6 text-green-500" aria-hidden="true" />
						<p class="ml-9 text-lg leading-6 font-medium text-gray-900">{{ feature.name }}</p>
					</dt>
					<dd class="mt-2 ml-9 text-base text-gray-500">
						{{ feature.description }}
					</dd>
				</div>
			</dl>
		</div>
	</div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/outline';

const features = [
	{
		name: 'B-BBEE Level 2',
		description: 'We are a B-BBEE Level 2 accredited company, with a recognition level of 125%',
	},
	{
		name: 'Ownership',
		description: 'We are a 51% black, designated group (Deaf), women-owned business',
	},
	{
		name: 'Service Options',
		description: 'We offer a variety of skills development solutions designed to meet your needs',
	},
	{
		name: 'Verified',
		description: 'Our offerings are aligned to the latest B-BBEE codes.',
	},
	{
		name: 'SETA Accredited',
		description: 'Our learnerships are SETA Accredited Qualifications, ranging from an NQF Level 2 to NQF Level 5.',
	},
	{
		name: 'Systems Driven',
		description: 'Our learner management system (LMS) allows you to track your learners and their progress.',
	},
];

export default {
	components: {
		CheckIcon,
	},
	setup() {
		return {
			features,
		};
	},
};
</script>

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@/assets/css/index.css';
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
import VueGtag from 'vue-gtag';

const app = createApp(App);

app.use(router);
app.use(VuePlyr, {
	plyr: {},
});
app.use(VueGtag, {
	includes: [
		{ id: 'AW-350289432' }
	],
	config: { id: 'G-GH4GB21D93' },
});
app.mount('#app');

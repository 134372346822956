<!-- This example requires Tailwind CSS v2.0+ -->
<template>
	<div class="bg-white pb-6">
		<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
			<div class="sectionheader text-center">Training Options</div>
			<div class="text-xs font-medium text-center text-primary-900">Select an option below for more information</div>
			<div class="mt-3 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-5 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
				<div v-for="option in learnerships" :key="option.name" class="content-card cursor-pointer group" @click="navRoute(option.route)">
					<div class="card-ribbon bg-primary-800">Learnership</div>
					<div class="tracking-tighter leading-6 text-gray-900">{{ option.name }}</div>
					<div class="mt-1 text-sm text-gray-500 pr-3">{{ option.shortDesc }}</div>
					<!-- <a :href="option.route" class="buttonprimary mt-6 tracking-tight">More about {{ option.name }}</a> -->
				</div>
				<div v-for="option in skills" :key="option.name" class="content-card cursor-pointer group" @click="navRoute(option.route)">
					<div class="card-ribbon bg-secondary-900">Skills Programme</div>
					<div class="tracking-tighter leading-6 text-gray-900">{{ option.name }}</div>
					<div class="mt-1 text-sm text-gray-500 pr-4">{{ option.shortDesc }}</div>
					<!-- <a :href="option.route" class="buttonprimary mt-6 tracking-tight">More about {{ option.name }}</a> -->
				</div>
			</div>
			<div class="sectionheader mt-12">The eDeaf Training Journey</div>
			<div class="pagepara">
				Every Deaf person has unique learning requirements and may register with us at any point on their educational journey. Many Deaf learners struggle with the concepts and
				demands of NQF2 and above. This is because there is a gap in understanding mathematics and language. Time may have passed between leaving school and joining eDeaf. AET and
				FLC provide a solid foundation for future learning and provides access to nationally recognized certificates.
			</div>
			<div class="pagepara">
				Depending on their personal interests, learners will then choose to follow occupational focused learnerships, sponsored by Corporates Our qualifications are in demand and
				relevant and lay the foundation for career development and create a culture of lifelong learning
			</div>
			<div class="pagepara">You can read more about AET and FLC under the Skills Programmes tabs in the menu.</div>
			<div class="mx-auto py-8 w-full">
				<img class="mx-auto" src="@/assets/images/edeaf-journey.jpg" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
//import { CheckIcon } from '@heroicons/vue/solid'
import site_data from '@/assets/data/site-data';

export default {
	data: () => {
		return {
			skills: site_data.SkillsProgrammes,
			learnerships: site_data.Learnerships,
			selectedOption: null,
			lactive: true,
		};
	},
	components: {
		//CheckIcon
	},
	mounted() {
		this.selectedOption = this.learnerships;
	},
	// a beforeMount call to add a listener to the window
	beforeMount() {},

	methods: {
		navRoute(route) {
			this.$router.push(route);
		},
	},
};
</script>
